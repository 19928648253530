@tailwind base;
@tailwind components;
@tailwind utilities;

.section {
  background-color: transparent;
}
.section-green {
  background-color: #29ce7c;
  position: relative;
  z-index: 99;
}
.section-black {
  background-color: #000000;
}
.section-white {
  background-color: #fff;
}
.section-indian-red {
  background-color: #e05d5d;
}
img.block.bone-grouped {
  position: absolute;
  top: 1300px;
  z-index: 999;
  width: 100%;
}
.navbar-link {
  text-decoration: none;
  color: #000;
  font-weight: 500;
}
.navbar-container {
  position: absolute;
  padding: 30px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
  color: #000;
}
/* TEST HOME */
.hidden-overflow {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.intro-text-container {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.meteorite-container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.btn-main {
  border: none;
  background: #ffffff;
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 100px;
  font-family: "KGHAPPYSolid";
}
.btn-main-border {
  border: 5px solid #000;
  background: #ffffff;
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 100px;
  font-family: "KGHAPPYSolid";
}
.btn-main-border-dino {
  border: 5px solid #000;
  background: #ffffff;
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 100px;
  font-family: "KGHAPPYSolid";
}

.text-green {
  color: #d4ff43;
  margin: 0;
}
.bg-black-line {
  background: #29ce7c;
}
.bg-green {
  background-color: #54d4f7;
}
.bg-line {
  width: 100%;
  height: 107px;
  display: block;
}

.bg-coming {
  background-image: url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/background_main.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100vh;
}

.bg-2xl {
  background-image: url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/00_2xl_1536px.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100vh;
}
.bg-xl {
  background-image: url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/01_xl_1280px.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100vh;
  display: none !important;
}
.bg-md {
  background-image: url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/02_medium_768px.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100vh;
  display: none !important;
}
.bg-sm {
  background-image: url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/04_small_640px.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100vh;
  display: none !important;
}
.bg-first {
  background-image: url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/05_first_428px.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100vh;
  display: none !important;
}
.bg-dinoNFT {
  background-image: url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/background_dinos.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
}

#rotate {
  animation: rotation 3s infinite linear;
  -webkit-animation: rotation 3s infinite linear;
  position: absolute;
  max-width: 100px;
  display: block;
  right: 69%;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
  }
}
@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
  }
}

.hidden-desk {
  display: none;
}
.hidden-desks {
  display: none;
}

@media only screen and (max-width: 1280px) {
  .bg-2xl {
    display: none !important;
  }
  .bg-xl {
    display: flex !important;
  }
  img.block.bone-grouped {
    display: none !important;
  }
  #rotate {
    animation: rotation 3s infinite linear;
    -webkit-animation: rotation 3s infinite linear;
    position: absolute;
    max-width: 100px;
    display: block;
    right: 77%;
  }
  .hidden-desk {
    display: block !important;
  }
  .hidden-mobile {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .bg-2xl {
    display: none !important;
  }
  .bg-xl {
    display: none !important;
  }
  .bg-md {
    display: flex !important;
  }
  #rotate {
    animation: rotation 3s infinite linear;
    -webkit-animation: rotation 3s infinite linear;
    position: absolute;
    max-width: 80px;
    display: block;
    right: 60%;
  }
  .hidden-desks {
    display: block !important;
  }
  .hidden-desk {
    display: none !important;
  }
  .hidden-mobile {
    display: none !important;
  }
}
.ciccio {
  padding-bottom: 170px;
}
.main-cont {
  position: relative;
  bottom: -170px;
}
@media only screen and (max-width: 640px) {
  .text-mob-h2 {
    padding-top: 80px;
  }
  .ciccio {
    padding-bottom: 0px !important;
  }
  .btn-main-border-dino {
    border: 5px solid #000;
    background: #ffffff;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 100px;
    font-family: "KGHAPPYSolid";
    margin: 10 0px;
  }
  .main-cont {
    position: static !important;
  }
  .bg-2xl {
    display: none !important;
  }
  .bg-xl {
    display: none !important;
  }
  .bg-md {
    display: none !important;
  }
  .bg-sm {
    display: flex !important;
  }
  .last-part {
    top: 40px !important;
  }
  .bg-dinoNFT {
    background-image: url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/04_small_640px.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }
}

/* FORM BRUTTO */

.App__form {
  display: flex;
  flex-direction: column;
}

input {
  padding: 7px 11px;
  border-radius: 3px;
  border: none;
  border-bottom: 1px solid gray;
  margin: 20px;
  outline: none;
}

button {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  padding: 7px 12px;
  border: none;
  border-radius: 4px;
}
