@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "KarlaItalic";
  src: local("KarlaItalic"),
    url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Font/KarlaItalic.ttf")
      format("truetype");
}
@font-face {
  font-family: "KarlaRegular";
  src: local("KarlaRegular"),
    url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Font/KarlaRegular.ttf")
      format("truetype");
}
@font-face {
  font-family: "KarlaBold";
  src: local("KarlaBold"),
    url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Font/KarlaBold.ttf")
      format("truetype");
}
@font-face {
  font-family: "KarlaExtraBold";
  src: local("KarlaExtraBold"),
    url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Font/KarlaExtraBold.ttf")
      format("truetype");
}
@font-face {
  font-family: "KGHAPPY";
  src: local("KGHAPPY"),
    url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Font/KGHAPPY.ttf")
      format("truetype");
}
@font-face {
  font-family: "KGHAPPYShadow";
  src: local("KGHAPPYShadow"),
    url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Font/KGHAPPYShadows.ttf")
      format("truetype");
}
@font-face {
  font-family: "KGHAPPYSolid";
  src: local("KGHAPPYSolid"),
    url("/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Font/KGHAPPYSolid.ttf")
      format("truetype");
}

h2 {
  font-family: "KGHAPPYSolid";
}
p .font-extrabold {
  font-family: "KarlaExtraBold";
}
.font-KGHAPPY {
  font-family: "KGHAPPYSolid";
  font-size: 20px !important;
}
.font-karla {
  font-family: "KarlaRegular";
  font-size: 20px !important;
}
html {
  background-color: #54d4f7;
}
body {
  margin: 0;
  font-family: "KarlaRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #54d4f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*width*/
::-webkit-scrollbar {
  width: auto;
}

/*track*/
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

/*thumb*/
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
}
